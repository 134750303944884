<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container
      fluid
    >
      <b-row>
        <b-col
          cols="12"
          md="8"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filterUserSearch"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <b-input
              id="filterUserSearch"
              v-model="tableUsers.filter.search"
              placeholder="search here"
              autocomplete="off"
              debounce="1000"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="px-1"
        >
          <b-form-group
            label="Role"
            label-for="filterRole"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <v-select
              v-model="tableUsers.selected.role"
              placeholder="search role here"
              input-id="filterRole"
              class="vs-white"
              label="name"
              clearable
              append-to-body
              :options="(tableUsers.options.roles)"
              :loading="(tableUsers.fetching.roles)"
              :disabled="(tableUsers.busy || tableUsers.fetching.roles)"
              :calculate-position="calculateDropPosition"
            >
              <template #option="{ name }">
                <div
                  class="d-flex py-1"
                  v-text="name"
                />
              </template>
              <template #no-options="">
                no available role
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="px-1 mb-2"
        >
          <b-button
            size="sm"
            type="button"
            variant="success"
            @click.prevent="createUser"
          >
            Add User
          </b-button>
        </b-col>
      </b-row>

      <b-row
        class="mt-1"
      >
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableUsers"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :items="tableProvider"
            :busy="tableUsers.busy"
            :filter="tableUsers.filter"
            :fields="tableUsers.fields"
            :stacked="tableUsers.stacked"
            :sort-by.sync="tableUsers.sortBy"
            :sort-desc.sync="tableUsers.sortDesc"
            :sort-direction="tableUsers.sortDirection"
            :filter-included-fields="tableUsers.filterOn"
            :current-page="tableUsers.currentPage"
            :per-page="tableUsers.perPage"
          >
            <template #cell(index)="{ index }">
              {{ tableRowNumber(tableUsers, index) }}
            </template>

            <template #cell()="{ value }">
              <div class="text-md-nowrap">
                {{ value }}
              </div>
            </template>

            <template #cell(action)="{ item }">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent="editUser(item)"
                >
                  Edit
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableUsers.perPage"
            :options="tableUsers.pageOptions"
            :disabled="tableUsers.busy"
            class="w-100 w-md-25"
            size="sm"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableUsers.currentPage"
            :per-page="tableUsers.perPage"
            :total-rows="tableUsers.totalRows"
            :disabled="tableUsers.busy"
            aria-controls="table"
            prev-text="Prev"
            next-text="Next"
            first-number
            last-number
            pills
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      id="modalUser"
      scrollable
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      :title="ModalUserTitle"
    >
      <ValidationObserver
        ref="formUser"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="first name"
                vid="first_name"
                rules="required|max:50"
              >
                <b-form-group
                  label="First Name"
                  label-for="first_name"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="first_name"
                    v-model="user.first_name"
                    type="text"
                    maxlength="50"
                    autocomplete="off"
                    placeholder="enter first name here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="last name"
                vid="last_name"
                rules="required|max:50"
              >
                <b-form-group
                  label="Last Name"
                  label-for="last_name"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="last_name"
                    v-model="user.last_name"
                    type="text"
                    maxlength="50"
                    autocomplete="off"
                    placeholder="enter last name here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="notification email"
                vid="notification_email"
                rules="email|max:50"
              >
                <b-form-group
                  label="Notification Email"
                  label-for="notification_email"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="notification_email"
                    v-model="user.notification_email"
                    type="email"
                    maxlength="50"
                    autocomplete="off"
                    placeholder="enter notification email here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="username"
                vid="username"
                rules="required|max:50"
              >
                <b-form-group
                  label="Username"
                  label-for="username"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="username"
                    v-model="user.username"
                    type="text"
                    maxlength="50"
                    autocomplete="off"
                    placeholder="enter username here"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="password"
                vid="password"
                :rules="vPassword"
              >
                <b-form-group
                  label="Password"
                  label-for="password"
                  description="Leave blank when not changing password."
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input-group
                    :class="{ 'is-invalid': errors.length > 0 }"
                  >
                    <b-input
                      id="password"
                      v-model="user.password"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="enter password here"
                      :type="passwordType"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <b-input-group-append>
                      <b-button
                        class="pl-2 pr-1 text-dark input-icon"
                        variant="success"
                        :disabled="state.busy"
                        @click.prevent="togglePasswordType"
                      >
                        <i
                          v-if="state.password_visible"
                          class="ri-eye-line h5"
                        />
                        <i
                          v-else
                          class="ri-eye-off-line h5"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="password confirmation"
                vid="password_confirmation"
                :rules="vPasswordConfirmation"
              >
                <b-form-group
                  label="Password Confirmation"
                  label-for="password_confirmation"
                  description="Leave as blank when not changing password."
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="password_confirmation"
                    v-model="user.password_confirmation"
                    autocomplete="off"
                    maxlength="50"
                    placeholder="enter password confirmation here"
                    :type="passwordType"
                    :state="errors.length > 0 ? false : null"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="role"
                vid="role"
                rules="required"
              >
                <b-form-group
                  label="Role"
                  label-for="role"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <v-select
                    v-model="selected.role"
                    input-id="role"
                    placeholder="search role here"
                    label="name"
                    clearable
                    append-to-body
                    :options="(options.roles)"
                    :loading="(fetching.roles)"
                    :disabled="(state.busy || fetching.roles)"
                    :calculate-position="calculateDropPosition"
                  >
                    <template #option="{ name }">
                      <div
                        class="d-flex py-1"
                        v-text="name"
                      />
                    </template>
                    <template #no-options="">
                      no available role
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                vid="location"
                name="location"
                :rules="{
                  required: [1, 2].includes(Number(user.role))
                }"
              >
                <b-form-group
                  label="Location"
                  label-for="location"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <v-select
                    v-model="selected.location"
                    input-id="location"
                    label="location_name"
                    clearable
                    append-to-body
                    placeholder="search location here"
                    :options="options.locations"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    :disabled="(state.busy || fetching.locations || [3, 4].includes(Number(user.role)))"
                    :loading="fetching.locations"
                  >
                    <template #option="{ active, allow_out, allow_in, location_code, location_name }">
                      <div class="d-flex flex-column py-1">
                        <div v-text="location_name" />
                        <div class="d-flex justify-content-between">
                          <strong v-text="location_code" />
                          <div>
                            <strong
                              class="text-success mr-1"
                              v-text="(Number(allow_in) === 1 ? 'in' : '')"
                            />
                            <strong
                              class="text-success mr-1"
                              v-text="(Number(allow_out) === 1 ? 'out' : '')"
                            />
                            <strong
                              :class="(Number(active) === 1 ? 'text-success' : 'text-danger')"
                              v-text="(Number(active) === 1 ? 'active' : 'inactive')"
                            />
                          </div>
                        </div>
                      </div>
                    </template>
                    <template #no-options="">
                      no available location
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              v-if="state.editing"
              cols="12"
            >
              <b-form-group
                class="mb-2"
              >
                <b-form-checkbox
                  id="is_active"
                  v-model="user.active"
                  switch
                  value="1"
                  name="is_active"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click.prevent="validateUser"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click.prevent="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import QUERY from 'lodash'
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'
import { SSharedList, SAdminUser } from '@/services'

export default {
  name: 'AdminUsers',

  middleware: [
    'auth',
    'admin'
  ],

  mixins: [
    MISC,
    FORMATTER
  ],

  metaInfo: () => ({
    title: 'Users'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        password_visible: false
      },
      fetching: {
        roles: false,
        locations: false
      },
      options: {
        roles: [],
        locations: []
      },
      selected: {
        role: null,
        location: null
      },
      user: {
        id: null,
        username: null,
        first_name: null,
        last_name: null,
        notification_email: null,
        password: null,
        password_confirmation: null,
        role: null,
        location: null,
        login_local: 0,
        active: 1
      },
      tableUsers: {
        busy: false,
        fetching: {
          roles: false
        },
        options: {
          roles: []
        },
        selected: {
          role: null
        },
        filter: {
          search: null,
          role: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 9, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'username' },
          { mobile: 3, key: 'first_name' },
          { mobile: 4, key: 'last_name' },
          { mobile: 5, key: 'notification_email' },
          { mobile: 6, key: 'role.name', label: 'Role' },
          { mobile: 6, key: 'location.location_name', label: 'Location' },
          { mobile: 7, key: 'active', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 8, key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    ModalUserTitle () {
      return this.state.editing ? 'Edit User' : 'Add User'
    },

    vPassword () {
      return `${this.state.editing ? '' : 'required|'}min:8`
    },

    vPasswordConfirmation () {
      return `${this.state.editing ? '' : 'required|'}confirmed:password`
    },

    passwordType () {
      return this.state.password_visible ? 'text' : 'password'
    }
  },

  watch: {
    'selected.role' (role) {
      this.user.role = role?.id || null

      if ([3, 4].includes(Number(role?.id))) {
        this.selected.location = null
      }
    },

    'selected.location' (location) {
      this.user.location = location?.id || null
    },

    'tableUsers.selected.role' (role) {
      this.tableUsers.filter.role = role?.id || null
    }

  },

  mounted () {
    core.index()
    this.getFilterRoles()
  },

  methods: {

    togglePasswordType () {
      this.state.password_visible = !this.state.password_visible
    },

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableUsers.busy = true
      return await SAdminUser.get({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        filter_role: filter.role
      }).then(({ data }) => {
        this.tableUsers.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableUsers.totalRows = 0
        return []
      }).finally(() => {
        this.tableUsers.busy = false
      })
    },

    async getFilterRoles () {
      this.tableUsers.fetching.roles = true
      return await SSharedList.getRoles().then(({ data }) => {
        this.tableUsers.options.roles = data
      }).catch(() => {
        this.tableUsers.options.roles = []
      }).finally(() => {
        this.tableUsers.fetching.roles = false
      })
    },

    async getRoles () {
      this.fetching.roles = true
      return await SSharedList.getRoles().then(({ data }) => {
        this.options.roles = data
      }).catch(() => {
        this.options.roles = []
      }).finally(() => {
        this.fetching.roles = false
      })
    },

    async getLocations () {
      this.fetching.locations = true
      return await SSharedList.getLocations().then(({ data }) => {
        this.options.locations = data
      }).catch(() => {
        this.options.locations = []
      }).finally(() => {
        this.fetching.locations = false
      })
    },

    createUser () {
      this.state.editing = false
      this.user.id = null
      this.user.username = null
      this.user.first_name = null
      this.user.last_name = null
      this.user.notification_email = null
      this.user.password = null
      this.user.password_confirmation = null
      this.user.active = 1

      this.getRoles().finally(() => {
        this.getLocations()
      })

      this.$bvModal.show('modalUser')
    },

    editUser (user) {
      this.state.editing = true
      this.user.id = user.id
      this.user.username = user.username
      this.user.first_name = user.first_name
      this.user.last_name = user.last_name
      this.user.notification_email = user.notification_email
      this.user.password = null
      this.user.password_confirmation = null
      this.user.active = user.active

      this.getRoles().finally(() => {
        this.selected.role = QUERY.find(this.options.roles, {
          id: Number(user.role_id)
        })

        this.getLocations().finally(() => {
          if ([1, 2].includes(Number(user.role_id))) {
            this.selected.location = QUERY.find(this.options.locations, {
              id: Number(user.location_id)
            })
          } else {
            this.selected.location = null
          }
        })
      })

      this.$bvModal.show('modalUser')
    },

    async validateUser (event) {
      event.preventDefault()
      await this.$refs.formUser.validate().then(
        async validUser => {
          if (!validUser) {
            return this.swalInvalid()
          }

          this.swalConfirm({
            html: () => {
              if (this.state.editing) {
                return '<h6>Save your Changes on this User?</h6>'
              }
              return '<h6>Create this User?</h6>'
            },
            preConfirm: () => {
              this.state.busy = true
              if (this.state.editing) {
                return this.updateUser()
              }
              return this.submitUser()
            }
          })
        }
      )
    },

    async submitUser () {
      return await SAdminUser.post(this.user).then(
        ({ data: { message } }) => {
          this.$bvModal.hide('modalUser')
          this.swalSuccess(message).then(() => {
            this.$refs.tableUsers.refresh()
          })
        }
      ).catch(({ message }) => {
        this.$refs.formUser.setErrors(message)
      }).finally(() => {
        this.state.busy = false
      })
    },

    async updateUser () {
      return await SAdminUser.put(this.user).then(
        ({ data: { message, user } }) => {
          this.$bvModal.hide('modalUser')
          this.swalSuccess(message).then(() => {
            const tableUsersRow = QUERY.find(
              this.$refs.tableUsers.localItems, {
                id: user.id
              })
            if (tableUsersRow) {
              tableUsersRow.username = user.username
              tableUsersRow.first_name = user.first_name
              tableUsersRow.last_name = user.last_name
              tableUsersRow.notification_email = user.notification_email
              tableUsersRow.role = user.role
              tableUsersRow.role_id = user.role_id
              tableUsersRow.location = user.location
              tableUsersRow.location_id = user.location_id
              tableUsersRow.active = user.active
              tableUsersRow.updated_at = user.updated_at
            }
          })
        }
      ).catch(({ message }) => {
        this.$refs.formUser.setErrors(message)
      }).finally(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
